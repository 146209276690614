import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'



const CartCountSlice = createSlice({
    name: 'product-count',
    initialState: {
        data: {},
    },
    reducers: { getCartCount: (state, action) => { state.data = action.payload } },


})

export const { getCartCount } = CartCountSlice.actions
export default CartCountSlice.reducer


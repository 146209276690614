


export const ScrollToPageTop = (errorRef) => {
  window.scrollTo({
    top: errorRef ? errorRef?.offsetTop : 0,
    behavior: 'instant'
  });
}
export const ScrollToElement = (elementRef) => {
  elementRef?.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
}


export const handleLogout = () => {
  // try {
  // await logoutApi({ tokenId: localStorage.getItem("customerToken") }).unwrap();
  localStorage.removeItem("customerToken")
  window.location.href = '/customer/account/login';
  // }
  // catch (e) {
  //   toast(e?.message, { type: "error" })
  //   return false
  // }
}

export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
  });
};
export const capitalizeFirstWord = (title) => {
  if (!title) return '';
  return title
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatNumber = (value) => {
  // Remove non-numeric characters
  const numericValue = value.replace(/[^\d]/g, '');
  return numericValue
};

export const homeSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Hallman Industries",
    "url": process.env.REACT_APP_BASE_URL,
    "logo": `${process.env.REACT_APP_AmazonLink}/header/logo.png`,
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "support@hallmanindustries.com",
      "telephone": "+1-512-444-2775",
      "contactType": "Customer Support"
    },
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "2502 Hwy 304",
      "addressLocality": "Smithville",
      "addressRegion": "TX",
      "postalCode": "78957",
      "addressCountry": "US"
    },
    "sameAs": [
      "https://www.facebook.com/hallmanindustries/",
      "https://x.com/HallmanTweets",
      "https://www.pinterest.com/hallmanindustries/"
    ],
    "description": "The HALLMAN brand marries elegant design with durable components to bring high-quality professional-style appliances to your home at an affordable price.",
    "founder": {
      "@type": "Person",
      "name": "Ben Hallman"
    },
    "foundingDate": "2005-06",
    "numberOfEmployees": "N/A"
  
  }
}


export const productSchema = (Detail) => {
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": Detail?.name,
    "image": Detail?.main_image,
    "description": Detail.short_description,
    "sku": Detail?.sku?.toLowerCase(),
    "brand": {
      "@type": "Brand",
      "name": "Hallman Industries"
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": Detail?.price,
      "itemCondition": "https://schema.org/NewCondition",
      "availability": Detail?.warehouse_inventory > 0 ? true : false,
      "seller": {
        "@type": "Organization",
        "name": "Hallman Industries"
      }
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.7",
      "reviewCount": "412"
    },
    "review": [
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Brian B."
        },
        "datePublished": "2024-07-24",
        "reviewBody": "Good exp",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Shaun S."
        },
        "datePublished": "2024-07-22",
        "reviewBody": "quick and easy",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Beverly W."
        },
        "datePublished": "2024-07-22",
        "reviewBody": "What is my delivery date",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4",
          "bestRating": "5"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Curtis G."
        },
        "datePublished": "2024-07-20",
        "reviewBody": "thanks for all your help",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        }
      }
    ]
  }
  
}

export const preBuySchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Do you ship to my area ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Shipping Options(please note, shipping does not include installation):\n\
  Threshold Shipping (First Dry Room, usually a garage): Complimentary\n\
  White Glove Shipping: $249\n\
  This option includes delivery to the \"room of choice,\" unboxing, and removal of all creating and packaging material.\n\
  Shipping Policy:\n\
  \n\
  We offer shipping to the contiguous United States as well as to Hawaii, Alaska, and internationally. Please note that shipping to Hawaii, Alaska, and international destinations will incur additional surcharges due to increased shipping costs. The surcharge amount will be quoted at time of shipping and payment can be made at that time or at time of order by emailing shipping@hallmanindustries.com. Please provide any additional information that may affect the delivery of your order in the Delivery Questionnaire:\n\
  \n\
  Are there any stairs or steps involved in the delivery path?\n\
  Are there narrow hallways or doorways that may pose challenges for large items?\n\
  Do you have any specific delivery instructions or access limitations?\n\
  Are there any other factors our delivery team should be aware of to ensure a smooth delivery process?\n\
  If Your input will help us plan and execute the delivery of your order efficiently. Thank you for your cooperation!\n\
  Please reach out to shipping@hallmanindustries.com to get a shipping quote or with any questions."
      }
    }, {
      "@type": "Question",
      "name": "Are the Range Dimensions Approximate or exact ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The width, height, and depth are exact. The exterior width is an approximation."
      }
    }, {
      "@type": "Question",
      "name": "Do you have Gas Line Specs ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Gas line connections are on the right backside of the oven when facing the range from behind. It is approximately 11” from the floor. Please see the dimensions guide if you need an exact placement of the gas line."
      }
    }, {
      "@type": "Question",
      "name": "Can we design a Hallman in any of the RAL colors Groups ? i.e. RAL Classic, RAL Design, etc",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, all you would need to do is provide us with the RAL Number, we then can design the range in your custom color choice."
      }
    }, {
      "@type": "Question",
      "name": "What is the size thread of the Gas connection ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It comes with the standard 1/2\" inch for gas connection."
      }
    }, {
      "@type": "Question",
      "name": "What should the min. height between the range and hood be ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "25.59 inches"
      }
    }, {
      "@type": "Question",
      "name": "What is the maximum circuit breaker needed ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Under the Power category on your selected range’s spec sheet has the Amperage specifications. The AMP minimum and maximum will vary by model."
      }
    }, {
      "@type": "Question",
      "name": "Can I have color and metal finish samples mailed to me ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, please all you need to do is submit your requested color and finish samples to sales@hallmanindustries.com."
      }
    }, {
      "@type": "Question",
      "name": "Is the brass finish lacquered or unlacquered ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It is unlacquered, but it does come with a protective coating to maintain the original finish color."
      }
    }, {
      "@type": "Question",
      "name": "Does the warming drawer operate from residual oven cooking heat ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, which is good for warming dishes."
      }
    }, {
      "@type": "Question",
      "name": "Is the broiler gas or electric?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It is gas for an all gas range or electric for a duel fuel range."
      }
    }, {
      "@type": "Question",
      "name": "Which accessories come with the oven ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "INCLUDED category on your selected range’s spec sheet has Accessory specifications, the accessories included can vary by model."
      }
    }, {
      "@type": "Question",
      "name": "What are the over rack dimensions ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The space between the bottom rack and the top rack holder is 8.5 inches. There are multiple possible rack heights, with a standard of 2.25 inches between each rack height."
      }
    }, {
      "@type": "Question",
      "name": "Are the legs removable ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes"
      }
    }, {
      "@type": "Question",
      "name": "Are the legs adjustable ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, all legs are adjustable with an exception of the claw feet."
      }
    }, {
      "@type": "Question",
      "name": "Replace Timer ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove burner assemblies and drip pan above the timer.\n\
  Pop out buttons from the timer at front of range.\n\
  Remove both screws from above the timer from inside of the range."
      }
    }, {
      "@type": "Question",
      "name": "Adjust Burner Height ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove burner assembly.\n\
  Removes screws from the drip pan and raise a drip pan.\n\
  Loosen screws on burner tube cross support.\n\
  Raise to the desired height and then retighten support screws.\n\
  Reinstall in reverse order."
      }
    }]

  }
}

export const installerSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do I open the back of the Hallman range ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "This process should be completed by an authorized installer."
      }
    }, {
      "@type": "Question",
      "name": "How do I open the top of the Hallman range ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove screws from each drip pan.\n\
  Remove burner assemblies.\n\
  Then lift and remove."
      }
    }, {
      "@type": "Question",
      "name": "How do I remove the oven door off of a Hallman range ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Using a needle nose plyer pull out the latch and push down. Do this on both sides.\n\
  Open warming drawer.\n\
  Lift door to about a 90-degree angle then lift door and remove."
      }
    }, {
      "@type": "Question",
      "name": "Installing legs on a Hallman ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Unpack the range and remove the grates from the cooktop.\n\
  Remove the burners.\n\
  Remove the oven doors.\n\
  Remove the drawer.\n\
  Remove all accessories from inside the oven.\n\
  With all the excess weight removed, place a protective mat o the ground for the range to lay on.\n\
  Place legs on the range and use screws to secure. Once secured sit range upright."
      }
    }, {
      "@type": "Question",
      "name": "Conversion from Gas to Propane ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "This process should be completed by a licensed plumber or a professional servicer."
      }
    }, {
      "@type": "Question",
      "name": "Replacing over door glass ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "His process should be completed by a licensed plumber or a professional servicer."
      }
    }, {
      "@type": "Question",
      "name": "Replace gasket ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Gasket is secured by small prongs. Pull gently and remove."
      }
    }, {
      "@type": "Question",
      "name": "Installing Gas Regulator ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Working with gas can be dangerous, even fatal, so we advise working with a trained professional. Do not attempt to install a gas regulator unless you have a thorough knowledge of the process beforehand."
      }
    }, {
      "@type": "Question",
      "name": "What you will need ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A wrench.\n\
  Pipe thread sealant\n\
  A covering to protect the floor\n\
  Locate the gas elbow connector on the rear of the range. Use a wrench to loosen it and you can safely unscrew it with your hands.\n\
  Apply a large amount of pipe thread sealant to the gas elbow connector and screw it into the regulator. ( the side with the yellow cap )\n\
  Install the gas supply fitting to the other port of the regulator with a pipe thread sealant applied.\n\
  Install gas supply lines.\n\
  Apply the Leak test solution.\n\
  Turn on the gas.\n\
  If bubbles form this means there is a gas leak. If there are no bubbles the range is safe to use."
      }
    }, {
      "@type": "Question",
      "name": "Converting the Gas Regulator for LP gas ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Unscrew the cap from the regulator. Do not remove the spring from the regulator.\n\
  Unscrew the plastic insert from the cap and turn it over so the longer stem is facing the cap.\n\
  Replace insert on the cap. Place the cap on the regulator."
      }
    }, {
      "@type": "Question",
      "name": "Replacing the Ignitor ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove burner assemblies.\n\
  Remove screws from the drip pan.\n\
  Raise a drip pan.\n\
  Clip plastic pieces securing both wires and remove the wire that needs to be replaced.\n\
  Remove C-clip and spring from the igniter.\n\
  Remove igniter from the drip pan.\n\
  Reinstall new igniter in reverse order."
      }
    }, {
      "@type": "Question",
      "name": "Positioning the Gas Burners ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Make sure all gas burners are aligned correctly. If either side of burner is lifted it is not on properly. Adjust until secured."
      }
    }, {
      "@type": "Question",
      "name": "Replace Timer ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove burner assemblies and drip pan above the timer.\n\
  Pop out buttons from the timer at front of range.\n\
  Remove both screws from above the timer from inside of the range."
      }
    }, {
      "@type": "Question",
      "name": "Replace gasket ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Gasket is secured by small prongs. Pull gently and remove."
      }
    }, {
      "@type": "Question",
      "name": "Adjust Burner Height ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove burner assembly.\n\
  Removes screws from the drip pan and raise a drip pan.\n\
  Loosen screws on burner tube cross support.\n\
  Raise to the desired height and then retighten support screws.\n\
  Reinstall in reverse order."
      }
    }]
  }

}

export const useCareSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What do I do when the buttons get stuck?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove the whole button and place it back into place."
      }
    }, {
      "@type": "Question",
      "name": "How do I set up the rotisserie?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Place drip pan at the bottom rack of range.\n\
  Place metal rack in center of the oven then place rod across the rack and insert the rod into rotisserie motor."
      }
    }, {
      "@type": "Question",
      "name": "How do I install the Griddle on my range?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Remove the grates from the long Fish Kettle Burner.\n\
  Install griddle legs on the bottom of the griddle. ( The two shorter legs in the front. )\n\
  Place the Griddle Vent by the backsplash.\n\
  Lay the griddle over the burner and rest it on the Griddle Vent."
      }
    }, {
      "@type": "Question",
      "name": "How would I clean the black burners?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Allow the product to cool down before cleaning it. It is recommended not to place it in cold water while still hot.\n\
  Wash with warm water and neutral detergent. Rub with a cloth or sponge – It is recommended to use natural cellulose and non-abrasive.\n\
  Do not use dust or steel wool cloths, and do not use abrasive sponges.\n\
  Do not let food be burned on the burner in order to avoid stains/discoloration on the surface of the burner. This will not alter the functionality of the product, and in some cases can be removed with the following procedure: Immerse the product in hot water with detergent then Wipe gently with a cloth. If stains are not eliminated, use warm white vinegar. Gently rub to get off stains.\n\
  Avoid the burners being in contact with food for a prolonged period. Especially if foods are acidic.\n\
  It is recommended to avoid contact with metal objects; if necessary substitute metal objects with wooden or plastic objects.\n\
  Avoid washing in the dishwasher. This product is not dishwasher safe and would get irreparably damaged.\n\
  In case of malfunction, be sure that all the holes in the outer part of the crown are perfectly clean."
      }
    }, {
      "@type": "Question",
      "name": "How do I install the self-cleaning panels?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "First, remove the side grids\n\
  Install the fan cover and the self-cleaning panels using the screws.\n\
  Mount the self-cleaning panels onto the special supports.\n\
  Latch the side grids, securing them around their designated screws."
      }
    }, {
      "@type": "Question",
      "name": "How do I install the toe-kick?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Measure the space between the top of the Oven legs and the ¬door to ensure there is enough space to install the toe-kick.\n\
  Insert the toe-kick between the bottom of the range and the floor. Be sure to firmly hold the toe-kick above the floor as you insert it to prevent scraping damage.\n\
  Use a level edge and make sure the toe-kick is flush with the range to your satisfaction.\n\
  NOTE: A half-inch clearance is required between the range’s bottom and the top of the toe-kick for easy insertion and removal"
      }
    }]
  }

}
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';

const ConfirmationModal = ({show, message, onConfirm, onCancel }) => {

  return (
    <Modal show={show.isOpen} aria-labelledby="contained-modal-title-vcenter "
    centered size="md" className="confirm-popup confirming">
      <Modal.Header className="d-flex align-items-center justify-content-between p-4 modalHeader">

        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={onCancel}>
          <></>
        </button>
      </Modal.Header>
      <Modal.Body>


        <div className="popup-content confir">

          <p className="pb-4 text-center w-80 m-auto">{message}</p>
          <div className="d-flex justify-content-center gap-2">

            <a className="unfilled-button"
              onClick={onCancel}
            >Cancel</a>
            <a className="filled-button"
              onClick={onConfirm}
            >Confirm</a>

          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}


export default ConfirmationModal
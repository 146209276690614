import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/App.css";
import "./styles/new-hallman.css";
import "./styles/Style.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import App from "./App";

const root = document.getElementById("root");
const rootInstance = ReactDOM.createRoot(root);

rootInstance.render(
  <App/>
);
import { createSlice } from '@reduxjs/toolkit'



const CategorySlice = createSlice({
    name: 'categories',
    initialState: {
        data: [],
    },
    reducers: { Categories: (state, action) => { state.data = action.payload } },


})

export const { Categories } = CategorySlice.actions
export default CategorySlice.reducer


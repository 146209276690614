import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useNotifyBackInStockMutation } from "../../features/apiSlice";
import { toast } from "react-toastify";

const NotifyBackInStockModal = ({ enable, setModal, sku }) => {
    const [formValues, setFormValues] = useState({
        notifyEmail: "",
        notifySku: "",
    });

    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [notifyBackInStock] = useNotifyBackInStockMutation();

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.notifyEmail.trim()) {
            newErrors.notifyEmail = "Email is required";
        }
        else if (!/\S+@\S+\.\S+/.test(formValues.notifyEmail)) {
            newErrors.notifyEmail = "Email format is invalid";
        }
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);
        try {
            const response = await notifyBackInStock({
                email: formValues.notifyEmail,
                sku: sku,
            }).unwrap();

            if (response[0]?.status === true) {
                toast("You will receive an email notification once the product is back in stock again!", { type: "success" });
                setModal(false);
            } else {
                toast("Failed to submit your request. Please try again.", { type: "error" });
            }
        } catch (error) {
            toast("An error occurred. Please try again later.", { type: "error" });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal show={enable} centered className="new-lower-modal" onHide={() => setModal(false)} >
            <Modal.Header className="modal-header d-flex align-items-center justify-content-center border-0">
                <div className="n-lower-logo">
                    <Link to="/" title="logo" onClick={() => setModal(false)}>
                        <img src={`${process.env.REACT_APP_AmazonLink}/header/logo.png`} alt="logo" title="logo" width="135" height="35" />
                    </Link>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setModal(false)} ></button>
            </Modal.Header>
            <Modal.Body className="modal-body position-relative">
                <div className="new-lower-cont">
                    <div className="notify-main">
                        <form onSubmit={handleSubmit}>
                            <div className="notify-text">
                                <p>Please enter your email to get notified when the product is back In-Stock</p>
                                <input type="text" placeholder="Enter your email" name="notifyEmail" onChange={handleChange} />
                                {errors.notifyEmail && <span className="red error-message">{errors.notifyEmail}</span>}
                                <input type="hidden" name="notifySku" value={sku} />
                                <button className="form-btn-site" type="submit" disabled={isLoading}>
                                    {isLoading ? "Submitting..." : "SUBMIT"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default NotifyBackInStockModal;
import { createSlice } from '@reduxjs/toolkit';

const FullPageLoaderSlice = createSlice({
    name: 'pageLoader',
    initialState: {
        pageLoader: false,
    },
    reducers: { pageLoader: (state, action) => { state.pageLoader = action.payload } },


})

export const { pageLoader } = FullPageLoaderSlice.actions
export default FullPageLoaderSlice.reducer
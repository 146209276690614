import { combineReducers } from "@reduxjs/toolkit";
import searchReducer from "./searchEnableSlice";
import cartProductCountReducer from "./cartProductCountSlice";
import miniCartReducer from "./miniCartSlice";
import { apiSlice } from "./apiSlice";
import discountModalReducer from "./discountModalSlice";
import CategoryDataReducer from "./CategoryDataSlice";
import FullPageLoaderReducer from "./fullPageLoaderSlice";
import searchedProductReducer from "./searchProducts";

const rootReducer = combineReducers({
  categories: CategoryDataReducer,
  searchEnable: searchReducer,
  discountModal: discountModalReducer,
  searched: searchedProductReducer,
  cartCount: cartProductCountReducer,
  miniCartEnabled: miniCartReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,

  FullPageLoader: FullPageLoaderReducer
});

export default rootReducer;

import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        searchEnable: false,
    },
    reducers: { searchEnable: (state, action) => { state.searchEnable = action.payload } },


})

export const { searchEnable } = searchSlice.actions
export default searchSlice.reducer
import { createSlice } from '@reduxjs/toolkit';

const miniCartSlice = createSlice({
    name: 'miniCart',
    initialState: {
        enable: false,
    },
    reducers: { miniCartStatus: (state, action) => { state.enable = action.payload } },


})

export const { miniCartStatus } = miniCartSlice.actions
export default miniCartSlice.reducer
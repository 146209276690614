import { createSlice } from '@reduxjs/toolkit';

const discountModalSlice = createSlice({
    name: 'discountModal',
    initialState: {
        open: false,
        data: {},
        executeIdentifier: null,
    },
    reducers: { modalEnable: (state, action) => { state.open = action.payload.enable, state.data = action.payload.detail,state.executeIdentifier = action.payload.execute} },


})

export const { modalEnable } = discountModalSlice.actions
export default discountModalSlice.reducer
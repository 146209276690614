import { createSlice } from '@reduxjs/toolkit'



const searchedProductSlice = createSlice({
    name: 'productSearch',
    initialState: {
        data: {},
        currentPage:1,
        restart: true,
        searchValue: ""
    },
    reducers: { getSearched: (state, action) => { 
       
        state.currentPage = action.payload.pageNo
        state.data = action.payload.info;
        state.searchValue = action.payload.value;
        state.restart=  action.payload.restart;
    } },


})

export const { getSearched } = searchedProductSlice.actions
export default searchedProductSlice.reducer

